import { Component as ReactComponent } from 'react';
import type { ComponentType, ReactNode } from 'react';

import { UserContextShape as LegacyUserContextShape } from '../shapes';

import displayName from './display-name';

import type { UserContextShape } from '@skyscanner-internal/falcon-common-types/types/hotels-components/types';

type Props = {
  value: UserContextShape;
  children: ReactNode;
};

interface ContextProps {
  userContext: UserContextShape;
}

const ContextTypes = {
  userContext: LegacyUserContextShape,
};

class UserContextProvider extends ReactComponent<Props> {
  static childContextTypes = ContextTypes;

  getChildContext() {
    return {
      userContext: this.props.value,
    };
  }

  render() {
    return <> {this.props.children} </>;
  }
}

const withUserContextProvider = <P extends ContextProps>(
  Component: ComponentType<P>,
) => {
  const component = (
    props: Omit<P, 'userContext'>,
    { userContext }: ContextProps,
  ) => (
    // @ts-ignore we expect that userContext can be overwritten in the unit tests
    <Component userContext={userContext} {...(props as P)} />
  );

  component.contextTypes = ContextTypes;

  component.displayName = displayName(Component);

  return component;
};

export default UserContextProvider;
export { withUserContextProvider };
