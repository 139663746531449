import BpkLoadingButton from '@skyscanner/backpack-web/bpk-component-loading-button';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import { withI18n } from '../../../services/i18n';
import { withConfiguration } from '../../../skyscanner-application/configuration';
import GuestsRoomsChildrenSelect from '../../Common/GuestsRoomsChildrenSelect';
import DestinationSelector from '../DestinationSelector';

import FullScreenScrollableDateRangeSelector from './FullScreenScrollableDateRangeSelector';

import type { I18nShape } from '../../../services/i18n';
import type { ConfigurationShape } from '../../../skyscanner-application/types';
import type {
  AutosuggestSuggestionOrPopularDestinationShape,
  Maybe,
} from '../../../types';
import type { DestinationShape } from '@skyscanner-internal/falcon-common-types/types/hotels-components/types';

import STYLES from './HorizontalLayout.scss';

const cls = cssModules(STYLES);

type Props = {
  i18n: I18nShape;
  configs: ConfigurationShape;
  destination: DestinationShape;
  checkInDate: Date;
  checkOutDate: Date;
  destinationLabel: string;
  childrenAges: string[];
  adults: number;
  rooms: number;
  suggestions: AutosuggestSuggestionOrPopularDestinationShape;
  onChangeInputValue: Function;
  onClearSuggestions: Function;
  onSuggestionSelected: Function;
  onSuggestionsFetchRequested: Function;
  onChangeOpenCheckinSelector: Function;
  onDatesRangeApply: Function;
  getGuestsRoomsChildren: Function;
  lightLabel?: boolean;
  validDestination: Maybe<boolean>;
  submittingForm?: boolean;
  showSearchButton: boolean;
};

const HorizontalLayout = ({
  adults,
  checkInDate,
  checkOutDate,
  childrenAges,
  configs,
  destination,
  destinationLabel,
  getGuestsRoomsChildren,
  i18n: { translate },
  lightLabel = false,
  onChangeInputValue,
  onChangeOpenCheckinSelector,
  onClearSuggestions,
  onDatesRangeApply,
  onSuggestionSelected,
  onSuggestionsFetchRequested,
  rooms,
  showSearchButton,
  submittingForm = false,
  suggestions,
  validDestination,
}: Props) => (
  <>
    <div className={cls('HorizontalLayout')}>
      <DestinationSelector
        destinationLabel={destinationLabel}
        destination={destination}
        lightLabel={lightLabel}
        inputClassName={cls('HorizontalLayout__input')}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onClearSuggestions}
        suggestions={suggestions}
        onChangeOpenCheckinSelector={onChangeOpenCheckinSelector}
        onChangeInputValue={onChangeInputValue}
        valid={validDestination}
      />

      <div
        className={cls('HorizontalLayout__datesGuest')}
        data-test-id="search-controls-datesguest"
      >
        <FullScreenScrollableDateRangeSelector
          startDate={checkInDate}
          endDate={checkOutDate}
          onApply={onDatesRangeApply}
          isWithBannerAlert={configs && configs.enableCalendarWithPrice}
        />
        <GuestsRoomsChildrenSelect
          className={cls('HorizontalLayout__guestsRoomsChildren')}
          lightLabel={lightLabel}
          label={translate('HotelStay_label_guests_rooms')}
          hideLabel
          adults={adults}
          rooms={rooms}
          childrenAges={childrenAges}
          getGuestsRoomsChildren={getGuestsRoomsChildren}
        />
      </div>
    </div>
    {showSearchButton && (
      <BpkLoadingButton
        className={cls('HorizontalLayout__button')}
        data-test-id="search-button"
        featured
        large
        loading={submittingForm}
        submit
        type="submit"
      >
        {translate('SearchControls_label_Submit')}
      </BpkLoadingButton>
    )}
  </>
);

export default withI18n(withConfiguration(HorizontalLayout));
