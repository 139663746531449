import { COMPONENT_ACTION } from '../../../constants';
import logger from '../../services/logger';
import { getCurrentQueryParam } from '../../services/url/url';
import GaClient from '../ga-client';
import { ACTION_TYPE } from '../minievents/constants';
import { buildAdditionalInfoSearchResultSelectedMessage } from '../minievents/hotels-action';

import FunnelEventsClient from './funnel-events-client';

import type { DeviceShape } from '../../types';
import type {
  MetricsType,
  GaClientType,
  ElementEventTrackerReturn,
  ObserverClientReturn,
  FunnelEventsClientType,
} from '../types';

type Props = {
  deviceInfo: DeviceShape;
  pageName: string;
  region: string;
  trafficSource: string;
  elementEventTracker: ElementEventTrackerReturn;
  observerClient: ObserverClientReturn;
};

/**
 * Application Metrics
 * Stable Events that can be logged to multiple sources
 * */

class ApplicationMetrics implements MetricsType {
  ga: GaClientType;

  funnelEvents: FunnelEventsClientType;

  deviceInfo: DeviceShape;

  pageName: string;

  region: string;

  trafficSource: string;

  elementEventTracker: ElementEventTrackerReturn;

  firedEvents: { [eventName: string]: boolean };

  HOTELS_SEARCH_CONTROLS_CLICKED_EVENT = 'hotels-search-controls-clicked';

  HOTELS_SEARCH_CONTROLS_LOADED_EVENT = 'hotels-search-controls-loaded';

  constructor({
    deviceInfo,
    elementEventTracker,
    observerClient,
    pageName,
    region,
    trafficSource,
  }: Props) {
    this.funnelEvents = new FunnelEventsClient();
    this.pageName = pageName;
    this.region = region;
    this.ga = new GaClient(observerClient);
    this.deviceInfo = deviceInfo;
    this.trafficSource = trafficSource;
    this.elementEventTracker = elementEventTracker;
    this.firedEvents = {};
  }

  searchLoaded() {
    if (!this.isEventFired(this.HOTELS_SEARCH_CONTROLS_LOADED_EVENT)) {
      logger.event(this.HOTELS_SEARCH_CONTROLS_LOADED_EVENT);
      this.setEventFired(this.HOTELS_SEARCH_CONTROLS_LOADED_EVENT);
    }
  }

  // Indicates that the Search Button inside Search Controls has been submitted
  searchSubmitted() {
    if (!this.isEventFired(this.HOTELS_SEARCH_CONTROLS_CLICKED_EVENT)) {
      logger.event(this.HOTELS_SEARCH_CONTROLS_CLICKED_EVENT);
      this.setEventFired(this.HOTELS_SEARCH_CONTROLS_CLICKED_EVENT);
    }
  }

  private isEventFired(eventName: string): boolean {
    return this.firedEvents[eventName];
  }

  private setEventFired(eventName: string) {
    this.firedEvents[eventName] = true;
  }

  // eslint-disable-next-line class-methods-use-this
  autoSuggestSearch({
    duration,
    status,
  }: {
    status: number;
    duration: number;
  }) {
    logger.event(COMPONENT_ACTION.HOTEL_AUTOSUGGEST.AUTOSUGGEST_SEARCH, {
      duration,
      status,
    });
  }

  searchResultSelected({
    cugDeal,
    currency,
    hotelId,
    isGoToHD,
    isMainPrice,
    isMapPanelHotel = false,
    isRecommendHotel = false,
    mainPriceArea,
    partnerId,
    position,
    price,
    searchId,
  }: {
    hotelId: number;
    position: number;
    price: number;
    partnerId: string;
    cugDeal: boolean;
    searchId: string;
    currency: string;
    isMainPrice: boolean;
    isGoToHD: boolean;
    mainPriceArea: string;
    isRecommendHotel?: boolean;
    isMapPanelHotel?: boolean;
  }) {
    const isUpSortHotels =
      getCurrentQueryParam('upsort_hotels')?.split(',')?.includes(hotelId) ??
      false;

    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.SEARCH_RESULT_SELECTED,
      buildAdditionalInfoSearchResultSelectedMessage({
        isCugDeal: cugDeal,
        isUpSortHotels,
        hotelId,
        isGoToHD,
        isMainPrice,
        isRecommendHotel,
        isMapPanelHotel,
        mainPriceArea,
        partnerId,
        position,
        price: price && { currency, amount: Math.round(price), unit: 'WHOLE' },
        searchId,
        pageName: this.pageName,
      }),
    );
  }
}

export default ApplicationMetrics;
